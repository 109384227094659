import { useState } from 'react';
import Grid from '@mui/material/Grid2';
import {
    Box,
    Button,
    CssBaseline,
    IconButton,
    Stack,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import Slider from 'react-slick';
import { colors } from '../styles/theme';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import DescriptionIcon from '@mui/icons-material/Description';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CampaignIcon from '@mui/icons-material/Campaign';
import InventoryIcon from '@mui/icons-material/Inventory';
import HelpIcon from '@mui/icons-material/Help';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';

const HomeScreen: React.FC = () => {
    const segments = [
        { label: 'Supermercado', icon: '🛒' },
        { label: 'Atacado/Atacarejo', icon: '📦' },
        { label: 'Açougue', icon: '🥩' },
        { label: 'Hortifruti', icon: '🥕' },
        { label: 'Conveniência', icon: '🏪' },
        { label: 'Panificadora', icon: '🍞' },
        { label: 'Varejo em geral', icon: '🏬' },
    ];

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    const solutions = [
        { icon: LocalMallIcon, text: 'Atacarejo' },
        {
            icon: CardMembershipIcon,
            text: 'Cartão Fidelidade',
        },
        { icon: MonetizationOnIcon, text: 'Promoção por Combinação' },

        { icon: CampaignIcon, text: 'Promoção por Quantidade' },
        { icon: CardGiftcardIcon, text: 'Vale-Presente' },
        { icon: EventAvailableIcon, text: 'Controle de Lote/Validade' },
        { icon: PersonalVideoIcon, text: 'PDV Off-line' },
        { icon: PhoneIphoneIcon, text: 'PDV Mobile' },
        { icon: PointOfSaleIcon, text: 'POS Integrado' },
        { icon: PaymentsIcon, text: 'TEF' },
        { icon: ReceiptIcon, text: 'Sped Contribuições (PIS/COFINS)' },
        { icon: HelpIcon, text: 'Contate-nos' },
    ];

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background',
                py: { xs: 3, md: 10 },
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        py: 5,
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            px: { xs: 3, md: 15 },
                            pt: 5,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { xs: 35, md: 60 },
                                fontWeight: 'bold',
                                lineHeight: 1,
                            }}
                        >
                            Quer decolar no mercado?
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: 35, md: 60 },
                                lineHeight: 1,
                                textAlign: { xs: 'end', md: 'end' },
                                color: 'secondary.dark',
                                pt: 1.5,
                            }}
                        >
                            A solução em um só{' '}
                            <Box component="span" sx={{ fontWeight: 'bold' }}>
                                lugar
                            </Box>
                            !
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: 16, md: 17 },
                                textAlign: { xs: 'center', md: 'center' },
                                lineHeight: 1.5,
                                pt: 7,
                                color: colors.secondary[400],
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                            }}
                        >
                            Sistemas • Equipamentos • instalação • suporte
                            especializado
                        </Typography>
                        <Typography
                            sx={{
                                color: 'secondary.dark',
                                textAlign: 'justify',
                                mt: 5,
                            }}
                        >
                            Oferecemos estabilidade e alta performance para uma
                            gestão integrada e lucrativa, impulsionando o
                            crescimento contínuo e estratégico da sua operação.
                            Com nosso suporte, você terá total controle e
                            segurança em cada etapa, otimizando processos e
                            maximizando resultados para atingir novos patamares
                            de sucesso.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            flex: 1,
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            alt="info"
                            id="responsiveImageInfo"
                            src="/assets/a_zul.png"
                        />
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: colors.grey[800],
                    py: 6,
                    mt: { xs: 0, md: 5 },
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        alt="tabs"
                        id="responsiveImageTabs"
                        src="/assets/foguete.png"
                    />
                </Box>

                <Box
                    sx={{
                        flex: 2,
                        textAlign: { xs: 'center', md: 'start' },
                        justifyContent: 'center',
                        width: { xs: '100%', md: '30%' },
                    }}
                >
                    <Typography
                        sx={{
                            color: 'primary.main',
                            fontSize: { xs: 20, md: 30 },
                            fontWeight: 'bold',
                            lineHeight: 1,
                            pt: { xs: 2, md: 0 },
                        }}
                    >
                        Segmentos
                    </Typography>
                    <Box
                        className="slider-container"
                        sx={{
                            width: '90%',
                            textAlign: 'center',
                            mt: 4,
                        }}
                    >
                        <Slider {...settings}>
                            {segments.map((segment, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingY: 2,
                                        borderRadius: 3,
                                        boxShadow: 0.3,
                                        backgroundColor: 'background.default',
                                    }}
                                >
                                    <Typography
                                        sx={{ fontSize: { xs: 25, md: 55 } }}
                                        fontWeight="bold"
                                    >
                                        {segment.icon}
                                    </Typography>
                                    <Typography variant="h5" fontWeight="bold">
                                        {segment.label}
                                    </Typography>
                                </Box>
                            ))}
                        </Slider>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    py: 10,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        maxWidth: {
                            xs: '90%',
                            md: '70%',
                        },
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: 'primary.main',
                            fontWeight: 'bold',
                            fontSize: { xs: 20, md: 30 },
                            lineHeight: 1,
                        }}
                    >
                        Nossas soluções
                    </Typography>
                    <Typography
                        sx={{
                            color: 'primary.main',
                            fontSize: { xs: 20, md: 30 },
                            lineHeight: 1,
                        }}
                    >
                        para sua empresa
                    </Typography>
                    <Grid container spacing={{ xs: 1, md: 2 }} pt={6}>
                        {solutions.map((solution, index) => (
                            <Grid
                                size={{ xs: 4, md: 2 }}
                                key={index}
                                sx={{
                                    backgroundColor: 'primary.main',
                                    borderRadius: 3,
                                    boxShadow: 7,
                                    p: 2,
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    height: 120,
                                }}
                            >
                                <solution.icon
                                    fontSize="large"
                                    color="secondary"
                                />
                                <Typography color="neutral.light">
                                    {solution.text}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};
export default HomeScreen;

{
    /* 


            <Box
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    py: 10,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        maxWidth: {
                            xs: '80%',
                            md: '70%',
                        },
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{ color: 'primary.main', fontWeight: 'bold' }}
                    >
                        Nossas soluções
                    </Typography>
                    <Typography variant="h3" sx={{ color: 'primary.main' }}>
                        para sua empresa
                    </Typography>
                    <Grid container spacing={2} pt={6}>
                        {solutions.map((solution, index) => (
                            <Grid
                                size={{ xs: 4, md: 2 }}
                                key={index}
                                sx={solutionBoxStyles}
                            >
                                <solution.icon
                                    fontSize="large"
                                    color="secondary"
                                />
                                <Typography color="neutral.light">
                                    {solution.text}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box> */
}
