import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import { light } from '@mui/material/styles/createPalette';

// color palette
export const colors = {
    primary: {
        100: '#05050A', //mais escuro
        200: '#151022',
        300: '#181429',
        400: '#18152D',
        500: '#191730',
        600: '#27264F',
        700: '#35366E',
        800: '#525DAC',
        900: '#626EB4', //mais claro
    },
    secondary: {
        100: '#08252F',
        200: '#10495E',
        300: '#186E8D',
        400: '#2092BC',
        500: '#28B7EB',
        600: '#53C5EF',
        700: '#7ED4F3',
        800: '#A9E2F7',
        900: '#D4F1FB',
    },
    grey: {
        100: '#1B1B1B',
        200: '#353535',
        300: '#505050',
        400: '#6A6A6A',
        500: '#858585',
        600: '#A0A1A3',
        700: '#E7E7E7',
        800: '#bcc6d6',
        900: '#FFFFFF',
    },
    greenAccent: {
        100: '#0C211C',
        200: '#184237',
        300: '#256353',
        400: '#31846E',
        500: '#3DA58A',
        600: '#64B7A1',
        700: '#8BC9B9',
        800: '#B1DBD0',
        900: '#EDF8F5',
    },
    redAccent: {
        100: '#2C100F',
        200: '#58201E',
        300: '#832F2C',
        400: '#AF3F3B',
        500: '#DB4F4A',
        600: '#E2726E',
        700: '#E99592',
        800: '#F1B9B7',
        900: '#F8DCDB',
    },
};

//mui theme settings
export const themeSettings = {
    palette: {
        primary: {
            dark: colors.primary[100],
            main: colors.primary[500],
            light: colors.primary[900],
        },
        secondary: {
            dark: colors.secondary[300],
            main: colors.secondary[500],
            light: colors.secondary[800],
        },
        neutral: {
            dark: colors.grey[100],
            main: colors.grey[500],
            light: colors.grey[900],
        },
        background: {
            default: colors.grey[700],
        },
    },
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontSize: 12,
        h1: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 40,
            color: colors.grey[200],
        },
        h2: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 32,
            color: colors.grey[200],
        },
        h3: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 24,
            color: colors.grey[200],
        },
        h4: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 20,
            color: colors.grey[200],
        },
        h5: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 18,
            color: colors.grey[200],
        },
        h6: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 14,
            color: colors.grey[200],
        },
    },
};

export const theme = createTheme(themeSettings);
