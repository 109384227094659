import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const AboutScreen: React.FC = () => {
    const slideIn = keyframes`
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `;

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                backgroundColor: 'background',
                py: { xs: 3, md: 10 },
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    px: { xs: 3, md: 15 },
                    pt: 5,
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: 35, md: 50 },
                        fontWeight: 'bold',
                        lineHeight: 1,
                    }}
                >
                    Sobre nós
                </Typography>

                <Typography
                    sx={{
                        fontSize: { xs: 35, md: 40 },
                        fontWeight: 'bold',
                        animation: `${slideIn} 1s ease-out`,
                        lineHeight: 1,
                        textAlign: { xs: 'end', md: 'end' },
                        color: 'secondary.dark',
                        pt: 1.5,
                    }}
                >
                    A AlternativaFoz!
                </Typography>

                <Typography
                    sx={{
                        color: 'primary.main',
                        textAlign: 'justify',
                        mt: 5,
                        textIndent: '3em',
                    }}
                >
                    Somos uma empresa especializada em automação comercial, e
                    nos destacamos por nossa oferta de assistência técnica e
                    softwares de{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                        gestão empresarial
                    </Box>
                    , direcionados especificamente para supermercados, mercados,
                    padarias e lojas de conveniência, independentemente de seu
                    porte.
                </Typography>

                <Typography
                    sx={{
                        color: 'primary.main',
                        textAlign: 'justify',
                        mt: 1,
                        textIndent: '3em',
                    }}
                >
                    A empresa diferencia-se por seu serviço personalizado,
                    adaptando-se às necessidades específicas de{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                        cada cliente
                    </Box>
                    . Após uma avaliação minuciosa das demandas de gestão do
                    cliente, a AlternativaFoz oferece o software mais adequado.
                </Typography>
            </Box>

            <Box
                sx={{
                    flex: 1,
                    textAlign: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    alt="info"
                    id="responsiveImageInfo"
                    src="/assets/bg_img.png"
                />
            </Box>
        </Box>
    );
};
export default AboutScreen;
