import { useEffect, useState } from 'react';
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import InstagramIcon from '@mui/icons-material/Instagram';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const TopBar = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleContactClick = () => {
        const phoneNumber = '5545999404992';
        const message = encodeURIComponent(
            'Olá, visitei o site da AlternativaFoz e desejo saber mais!'
        );
        const url = `https://wa.me/${phoneNumber}?text=${message}`;

        window.open(url, '_blank');
    };

    return (
        <AppBar
            color="primary"
            sx={{
                width: '100%',
                height: { xs: 50, md: 70 },
            }}
        >
            <Toolbar
                sx={{
                    paddingTop: { xs: 0, sm: 1 },
                    paddingBottom: { xs: 1, sm: 0 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        px: { xs: 2, md: 5 },
                    }}
                >
                    <img
                        src={
                            isMobile
                                ? '/assets/isotipo.png'
                                : '/assets/logo.png'
                        }
                        alt="Logo"
                        id="logoHeader"
                    />

                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        {['Início', 'Sobre nós'].map((text, index) => (
                            <Button
                                key={index}
                                onClick={() => {
                                    index === 0
                                        ? navigate('/')
                                        : // : navigate(`/${text.toLowerCase()}`);
                                          navigate('/about');
                                }}
                                sx={{
                                    fontSize: isMobile ? '9px' : '13px',
                                    color: 'neutral.light',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.1)',
                                        color: 'secondary.main',
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                {text}
                            </Button>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        {isMobile ? (
                            <IconButton onClick={handleContactClick}>
                                <ContactSupportIcon
                                    fontSize="large"
                                    sx={{
                                        color: 'secondary.light',
                                        transition:
                                            'transform 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                            color: 'secondary.main',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </IconButton>
                        ) : (
                            <Button
                                onClick={handleContactClick}
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    color: 'neutral.light',
                                    borderRadius: 30,
                                    px: 2,
                                    py: 1,
                                    textTransform: 'none',
                                    fontSize: 13,
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.1)',
                                        backgroundColor: 'secondary.dark',
                                    },
                                }}
                            >
                                Contatar um consultor
                            </Button>
                        )}
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
