import { Box, Typography } from '@mui/material';

const PDVScreen: React.FC = () => {
    return (
        <Box sx={{ backgroundColor: 'red', width: 300, height: 500 }}>
            <Typography>PDV Page</Typography>
        </Box>
    );
};
export default PDVScreen;
