import { useEffect, useState } from 'react';
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useNavigate } from 'react-router-dom';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

import InstagramIcon from '@mui/icons-material/Instagram';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { colors } from '../styles/theme';

const Footer = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <footer
            style={{
                backgroundColor: colors.primary[500],
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    py: { xs: 3, md: 3 },
                    color: 'neutral.light',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={'/assets/logo_footer.png'}
                        id="logoFooter"
                        alt="Logo"
                    />
                </Box>
                <Box
                    sx={{
                        flex: { xs: 1, md: 2 },
                        flexDirection: { xs: 'column', md: 'row' },
                        display: 'flex',
                        mr: 1,
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            flexDirection: 'column',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: 12, md: 18 },
                            }}
                        >
                            Contatos
                        </Typography>

                        <Box sx={{ display: 'flex', pt: 1 }}>
                            <LocalPhoneIcon
                                sx={{
                                    fontSize: { xs: 'small', md: 'medium' },
                                    color: 'secondary.main',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: { xs: 10, md: 13 },
                                    pl: { xs: 0.5, md: 1 },
                                }}
                            >
                                (45) 3025-5002
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex' }}>
                            <WhatsAppIcon
                                sx={{
                                    fontSize: { xs: 'small', md: 'medium' },
                                    color: 'secondary.main',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: { xs: 10, md: 13 },
                                    pl: { xs: 0.5, md: 1 },
                                }}
                            >
                                (45) 99940-4992
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex' }}>
                            <AlternateEmailIcon
                                sx={{
                                    fontSize: { xs: 'small', md: 'medium' },
                                    color: 'secondary.main',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: { xs: 10, md: 13 },
                                    pl: { xs: 0.5, md: 1 },
                                }}
                            >
                                atendimento@alternativafoz.com.br
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={'/assets/foguete_footer.png'}
                            alt="Logo"
                            id="imgFooter"
                        />
                    </Box>

                    <Box
                        sx={{
                            flex: 1,
                            pt: 1,

                            flexDirection: 'column',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: 12, md: 18 },
                            }}
                        >
                            Endereço
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                pt: 1,
                                alignItems: 'center',
                            }}
                        >
                            <FmdGoodIcon
                                sx={{
                                    fontSize: { xs: 'small', md: 'large' },
                                    color: 'secondary.main',
                                }}
                            />
                            <Typography
                                sx={{
                                    textAlign: 'start',
                                    fontSize: { xs: 10, md: 12 },
                                    pl: { xs: 0.5, md: 1 },
                                }}
                            >
                                Rua Araguaia, 232, sala 01, Campos do Iguaçu,
                                Foz do Iguaçu/PR - Brasil
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </footer>
    );
};

export default Footer;
